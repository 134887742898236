import React, { Component } from 'react';
import Container from './Container';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import ClockSet from './Clock';
import AddGameForm from './forms/AddGameForm';
import { getAllGames } from './client';
import { Avatar, Table, Spin, Modal, Empty, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { errorNotification } from './Notification';

const getIndicatorIcon = () => <LoadingOutlined style={{
  fontSize: 24,
  margin: 0,
  position: 'absolute',
  top: '50%',
}} spin />;

class App extends Component {

  state = {
    games: [],
    isFetching: false,
    isAddGameModalVisible: false
  }

  openAddGameModal = () => this.setState({ isAddGameModalVisible: true })
  closeAddGameModal = () => this.setState({ isAddGameModalVisible: false })

  componentDidMount() {
    this.fetchGames();
  }

  fetchGames = () => {
    this.setState({
      isFetching: true
    })
    getAllGames()
      .then(res => res.json()
        .then(games => {
          this.setState({
            games,
            isFetching: false
          });
        })).catch(error => {
          const message = error.message;
          const description = error.message;
          errorNotification(message, description);
          this.setState({
            isFetching: false
          });
        });
  }

  render() {

    const { games, isFetching, isAddGameModalVisible } = this.state;

    const commonElements = () => (
      <div>
        <Modal title='Add new game'
          open={isAddGameModalVisible}
          onOk={this.closeAddGameModal}
          onCancel={this.closeAddGameModal}
          width={1000}>
          <AddGameForm
            onSuccess={() => {
              this.closeAddGameModal();
              this.fetchGames();
            }}
          />
        </Modal>
        <Footer amountOfGames={games.length}
          handleAddGameEvent={this.openAddGameModal} />
      </div>
    )

    /*if (isFetching) {
      return (
        <Container>
          <Spin indicator={getIndicatorIcon()} />
        </Container>
      );
    }*/

    //if (games && games.length) {

      const columns = [
        {
          title: '',
          key: 'avatar',
          width: 50,
          render: (text, game) => (
            <Avatar size='large'>
              {`${game.title.charAt(0).toUpperCase()}`}
            </Avatar>
          )
        },
        {
          title: 'Game Id',
          dataIndex: 'id',
          key: 'id',
          width: 50
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          width: 1000
        }
        //,
        // {
        //   title: "",
        //   key: "Actions",
        //   align: "center",
        //   render: () => {
        //     return (
        //       <Button type="primary" danger
        //         onClick={event => window.open('space_shooter.html', '_blank')}
        //       >Play</Button>
        //     );
        //   },
        // }
      ];
      return (
        <Container>
          <Header />
          <ClockSet /> 
		  <br/><font color="#FFFFFF"> Data Base is disabled, but you still can play some games</font>
          <div className="row">
            <div className="column1">

			<table className='column1'>
                <tbody>
                  <tr>
                    <td className='first'>Foggy Space Shooter</td>
                   </tr>
                  <tr>
                    <td className='second'>Real F</td>
                  </tr>
                  <tr>
                    <td className='first'>Ogame</td>
                  </tr>
                  <tr>
                    <td className='second'>Gran Turismo 8</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="column2">
              <table className='tableButtons'>
                <tbody>
                  <tr>
                    <td><Button type="primary" danger className='play'
                      onClick={event => window.open('space_shooter.html', '_blank')}
                    >Play</Button></td>
                    <td className='version'>1.2.4.1</td>
                  </tr>
                  <tr>
					<td><Button type="primary" danger className='play'
                      onClick={event => window.open('realf.html', '_blank')}
                    >Play</Button></td>
                    <td className='version'>0.1.0</td>
                  </tr>
                  <tr>
                    <td><Button type="dashed" block className='play'
                    >Play</Button></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><Button type="dashed" block className='play'
                    >Play</Button></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {commonElements()}
        </Container>
      );
    //}
    return (
      <Container>
        <Header />
        <Empty description={
          //<h1>No games yet</h1>
		  <h1>Site is temporary down</h1>
        }
          style={{
            paddingTop: '50px',
            paddingBottom: '50px'
          }}
        />
        {commonElements()};
      </Container>
    )
  }
}

export default App;
