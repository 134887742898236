import { Avatar, Button } from "antd";
import React from "react";
import Continer from "./Container";
import './Footer.css';

const Footer = (props) => (
    <div className='footer'>
        <Continer>
            {props.amountOfGames ?
                <Avatar style={{ backgroundColor: '#f56a00', marginRight: '5px' }}
                        size='large'>{props.amountOfGames}</Avatar> : null
            }
            <Button style={{ display:'none' }} onClick={() => props.handleAddGameEvent()} type='primary'>Add new game</Button>
            <p className='copyRight'>Fog-Games ©2022-2025</p>
        </Continer>
    </div>
);

export default Footer;